import React from 'react'
import Helmet from 'react-helmet'

import { Link } from 'gatsby'

import Layout from '../layouts'
import { cityPath, METRO_PARIS_PATH } from '../path'

const ParisPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <Helmet title="MisterGoodBeer | Guide des bars de Paris" />
        <h1 className="title is-3">
          Le guide des bars de Paris
        </h1>
        <h2 className="title is-4">
          Les bars les moins chers de Paris
        </h2>
        <p>
          Pour les non-avertis, trouver un bar à Paris où la pinte est à moins de 8€ relève de
          l'exploit.
          Si avec un peu d'entrainement il est possible de trouver des bars qui servent une
          pinte à 3€ ou 4€, il est même possible de descendre à moins de 3€.
          Vous pouvez les trouver dans notre
          {' '}
          <Link to={cityPath({ name: 'Paris' })}>
            top des des bars les moins chers de Paris
          </Link>
          .
        </p>
        <br />
        <h2 className="title is-4">
          Les bars les moins chers par station de métro
        </h2>
        Mais le parisien pressé et sûr de lui doit pouvoir se repérer à tout moment par rapport
        au métro.
        Pour t'aider dans cette quête, voici les
        {' '}
        <Link to={METRO_PARIS_PATH}>
          bars les moins chers pour chaque station de métro
        </Link>
        . Une astuce qui n'est pas sans rappeler notre
        {' '}
        <Link to="/blog/visualiser_cartes_bars_bieres_paris_dataviz/">
           plan de métro des bières les moins chères.
        </Link>
      </div>
    </section>
  </Layout>
)

export default ParisPage
